import jQuery from 'jquery/dist/jquery';
import Utils from '../../../../utils'
import DefaultController from "../../../defaultController";

export default class ReportingOrderList extends DefaultController {
    tables: any;
    elements: any;
    tableNames: any;
    columns: any;
    //@ts-ignore
    private initComplete: boolean;

    async init() {
        this.initComplete = false;
        this.tableNames = ["orders"]
        this.bindListeners();
        this.setFilters();
        await this.loadTables();
        await this.configureColumns();
    }

    getEntityData(elem: any) {
        return {}
    }

    loadFilters() {
        const tableName = "orders"
        this.tables[tableName].columns().every((col: any) => {
            if (col !== 0) {
                const column = this.tables[tableName].column(col);
                const header = document.querySelector(`li.table_toggler[data-column='${col}']`) as HTMLElement
                let filterElem: any = null
                if (col === 2 || col === 4 || col === 5 || col === 8) {
                    filterElem = document.createElement('select');
                    filterElem.classList.add("form-select")
                    filterElem.multiple = true
                    filterElem.add(new Option("[Leer]", ""));
                    header.appendChild(filterElem);
                    filterElem.addEventListener("click", (e: any) => {
                        e.preventDefault();
                        e.stopPropagation();
                    })
                }
                const options: any = {}
                this.tables[tableName].data().unique().sort().each((d: any, j: any) => {
                    if (col === 2) {
                        if (!options[col]) {
                            options[col] = []
                        }
                        options[col].push(d.customer ? d.customer.company : "");
                    } else if (col === 4) {
                        if (!options[col]) {
                            options[col] = []
                        }
                        options[col].push(d.status);
                    } else if (col === 5) {
                        if (!options[col]) {
                            options[col] = ['']
                        }
                        options[col].push(d.status_delivery);
                    } else if (col === 8) {
                        if (!options[col]) {
                            options[col] = ['']
                        }
                        d.products.forEach((product: any) => {
                            options[col].push(product.name);
                        })
                    }
                });
                Object.keys(options).forEach((key: any) => {
                    const v = options[key].filter((value: any, index: any, array: string | any[]) => array.indexOf(value) === index).filter((n: any) => n).sort();
                    v.forEach((value: any) => {
                        filterElem.add(new Option(value, value));
                    })
                })

                const savedFilters = JSON.parse(localStorage.getItem(`tickware_export_filter_${tableName}`) as string)
                if (savedFilters && savedFilters[`col-${col}`]) {
                    const values = savedFilters[`col-${col}`].split("|")
                    values.forEach((v: any) => {
                        if (filterElem.querySelector(`option[value='${v}']`)) {
                            filterElem.querySelector(`option[value='${v}']`).setAttribute("selected", "true")
                        }
                    })
                    const ev = new Event("change")
                    filterElem.dispatchEvent(ev)
                }

                jQuery(filterElem).select2().on('select2:select select2:unselect', (e) => {
                    console.log("filterElem", filterElem)
                    //@ts-ignore
                    const values = Array.from(filterElem.querySelectorAll("option:checked")).map((e) => {return jQuery.fn.DataTable.util.escapeRegex(e.value)})
                    let savedFilters = JSON.parse(localStorage.getItem(`tickware_export_filter_${tableName}`) as string)
                    if (!savedFilters) {
                        savedFilters = {}
                    }
                    savedFilters[`col-${col}`] = values.join("|")
                    localStorage.setItem(`tickware_export_filter_${tableName}`, JSON.stringify(savedFilters))
                    if (col === 8) {
                        column.search(values.join("|").length > 0 ? values.join("|") : '', true, false).draw();
                    } else {
                        column.search(values.join("|").length > 0 ? '^' + values.join("|") + '$' : '', true, false).draw();
                    }
                    console.log("search", values.length > 0 ? '^' + values.join("|") + '$' : '', column)
                });
            }
        });
    }

    async loadTables() {
        this.elements = {};
        this.tables = {};
        this.columns = {};
        await this.loadTicketTable();
    }

    setFilters() {
        this.tableNames.forEach((tableName: string) => {
            let currentStorage = localStorage.getItem(`tickware_export_settings_${tableName}`)
            if (!currentStorage) {
                currentStorage = '{}'
            }
            let currentStorageO = JSON.parse(currentStorage)
            Object.keys(currentStorageO).forEach((storageIndex: any) => {
                const storageValue = currentStorageO[storageIndex]
                let htmlElement = document.querySelector(`li[data-table='${tableName}'][data-column='${storageIndex}']`)
                if (htmlElement) {
                    const icon = htmlElement.querySelector("i") as HTMLElement;
                    if (storageValue) {
                        icon.classList.remove("ti-eye-off")
                        icon.classList.add("ti-eye")
                    } else {
                        icon.classList.add("ti-eye-off")
                        icon.classList.remove("ti-eye")
                    }
                }
            })
        })
    }

    async configureColumns() {
        this.tableNames.forEach((tableName: string) => {
            let currentStorage = localStorage.getItem(`tickware_export_settings_${tableName}`)
            if (!currentStorage) {
                currentStorage = '{}'
            }
            let currentStorageO = JSON.parse(currentStorage)
            Object.keys(currentStorageO).forEach((storageIndex: any) => {
                const storageValue = currentStorageO[storageIndex]
                const table = this.tables[tableName]
                let column = table.column(storageIndex);
                column.visible(storageValue);
            })
        })
    }

    async loadTicketTable() {
        const tableName = "orders"
        this.columns[tableName] = []
        this.elements[tableName] = jQuery(`.table-${tableName} table`);
        this.tables[tableName] = this.elements[tableName].DataTable({
            deferRender: false,
            pageLength: 25,
            colReorder: true,
            ordering: false,
            initComplete: (r: any) => {
                this.elements[tableName].closest(".card").find(".loading-body").addClass("d-none")
                this.elements[tableName].closest(".card").find(".card-datatable").removeClass("d-none")
                this.loadFilters();
                if (!this.initComplete) {
                    this.initComplete = true
                    Utils.hideLoader();
                }
            },
            ajax: `/api/v1/erp/${tableName}/report`,
            order: [[0, 'desc']],
            columns: [
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'},
                {data: 'uuid'}
            ],
            columnDefs: [
                {
                    targets: 0,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `${full.number}`;
                    },
                },
                {
                    targets: 1,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `${full.created_at}`;
                    },
                },
                {
                    targets: 2,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return full.customer ? full.customer.company : "";
                    },
                },
                {
                    targets: 3,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `${full.total}€`;
                    },
                },
                {
                    targets: 4,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `${full.status}`;
                    },
                },
                {
                    targets: 5,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `${full.status_delivery}`;
                    },
                },
                {
                    targets: 6,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return `${full.documents.join(", ")}`;
                    },
                },
                {
                    targets: 7,
                    render: (data: any, type: any, full: any, meta: any) => {
                        return full.ticket ? full.ticket.title : "";
                    },
                },
                {
                    targets: 8,
                    render: (data: any, type: any, full: any, meta: any) => {
                        let str: any = [];
                        full.products.forEach((product: any) => {
                            let s = `${product.quantity}x ${product.name}`
                            if (product.serials.length > 0) {
                                s += `(${product.serials.join(", ")})`
                            }
                            str.push(s)
                        });
                        return str.join("<br />");
                    },
                },
                {
                    targets: 9,
                    render: (data: any, type: any, full: any, meta: any) => {
                        let str: any = [];
                        full.products.forEach((product: any) => {
                            let s = "";
                            if (product.assetTags.length > 0) {
                                s = `${product.assetTags.join(", ")}`
                            }
                            str.push(s)
                        });
                        return str.join("<br />");
                    },
                },
                {
                    targets: 10,
                    render: (data: any, type: any, full: any, meta: any) => {
                        let str: any = [];
                        full.products.forEach((product: any) => {
                            let s = "";
                            if (product.externalOrderNumbers.length > 0) {
                                s = `${product.externalOrderNumbers.join(", ")}`
                            }
                            str.push(s)
                        });
                        return str.join("<br />");
                    },
                },
            ],
            processing: true,
            dom:
                '<"row me-2 align-items-center"' +
                '<"col-md-2"<"me-3 m-3"l>>' +
                '<"col-md-10"<"dt-action-buttons text-xl-end text-lg-start text-md-end text-start d-flex align-items-center justify-content-end flex-md-row flex-column mb-3 mb-md-0"fB>>' +
                '>t' +
                '<"row mx-2 align-items-center justify-content-between"' +
                '<"col-6"i>' +
                '<"col-2 mt-3"p>' +
                '>',
            language: {
                sLengthMenu: '_MENU_',
                search: '',
                searchPlaceholder: `${Utils.translate('generic.search')}...`,
                "zeroRecords": `${Utils.translate('generic.datatable.no_results')}`,
                "emptyTable": `${Utils.translate('generic.datatable.no_results')}`,
                "paginate": {
                    "first": `${Utils.translate('generic.datatable.pagination.first')}`,
                    "last": `${Utils.translate('generic.datatable.pagination.last')}`,
                    "next": `${Utils.translate('generic.datatable.pagination.next')}`,
                    "previous": `${Utils.translate('generic.datatable.pagination.previous')}`
                },
                "info": `${Utils.translate('generic.datatable.info.info')}`,
                "infoEmpty": `${Utils.translate('generic.datatable.info.empty')}`,
                "infoFiltered": `${Utils.translate('generic.datatable.info.filtered')}`,
            },
            buttons: [
                {
                    text: `${Utils.translate('generic.download')}`,
                    className: 'dt-button btn btn-primary m-2 download-btn',
                    attr: {
                        "data-table": tableName
                    }
                }
            ]
        });

        this.columns[tableName] = this.tables[tableName].columns()[0];
        this.tables[tableName].on('column-reorder', (e: any, settings: any, details: any) => {
            this.columns[tableName] = Utils.arrayMove(this.columns[tableName], details.from, details.to)
            localStorage.setItem(`tickware_export_columns_${tableName}`, JSON.stringify(this.columns[tableName]))
        });
        const columnMapping = localStorage.getItem(`tickware_export_columns_${tableName}`)
        if (columnMapping) {
            const columnMappingO = JSON.parse(columnMapping)
            this.tables[tableName].colReorder.order(columnMappingO);
            this.columns[tableName] = columnMappingO
        }
    }

    bindListeners() {
        (document.querySelector("body") as HTMLElement).addEventListener("click", async (e: any) => {
            let target = e.target as HTMLElement;
            let element: any = null;
            if (target && target.classList.contains("download-btn")) {
                element = target
            //@ts-ignore
            } else if(target && target.parentNode.classList.contains("download-btn")) {
                element = target.parentNode
            }
            if(element) {
                const tableName = element.getAttribute("data-table") as string;
                const table = this.tables[tableName];
                if (table) {
                    let csv = '';
                    const csvHeader: any = []
                    table.columns().header().each((column: any, i: number) => {
                        const visible = table.column(i).visible();
                        if (visible) {
                            let content = column.innerHTML
                            if (content.indexOf("<span") !== -1) {
                                content = content.match(/>(.*)<\/span><span/)[1]
                            }
                            csvHeader.push(content)
                        }
                    })
                    csv += `${csvHeader.join(";")}\n`

                    table.rows({search:'applied'}).every((rowIndex: number) => {
                       let data: any = [];
                        table.columns().every((colIndex: number) => {
                            const col = table.column(colIndex)
                            const visible = col.visible()
                            if (visible) {
                                let content = table.cell(rowIndex,colIndex).node() ? table.cell(rowIndex,colIndex).node().innerHTML : "";
                                if (content.indexOf("<span") !== -1) {
                                    content = content.match(/>(.*)<\/span/)[1]
                                }
                                data.push(content.replaceAll("&amp;", "&").replaceAll(";", ""))
                            }
                        });
                        csv += `${data.join(";")}\n`
                    });
                    await Utils.download(new Blob(["\ufeff", csv]), 'export.csv')
                }
            }
        });

        document.querySelectorAll('li.table_toggler').forEach((el) => {
            el.addEventListener('click',  (e) => {
                e.preventDefault();
                //@ts-ignore
                if (e.target && e.target.nodeName !== "LI" && e.target.nodeName !== "I" ) {
                    return;
                }
                const target = e.currentTarget as HTMLElement;
                let columnIdx = target.getAttribute('data-column') as string;
                let tableName = target.getAttribute('data-table') as string;
                const table = this.tables[tableName];
                if (table) {
                    const actualColumnIdx = this.columns[tableName].indexOf(parseInt(columnIdx))
                    let column = table.column(actualColumnIdx);
                    column.visible(!column.visible());
                    const icon = target.querySelector("i") as HTMLElement;
                    if (column.visible()) {
                        icon.classList.remove("ti-eye-off")
                        icon.classList.add("ti-eye")
                    } else {
                        icon.classList.add("ti-eye-off")
                        icon.classList.remove("ti-eye")
                    }
                    let currentStorage = localStorage.getItem(`tickware_export_settings_${tableName}`)
                    if (!currentStorage) {
                        currentStorage = '{}'
                    }
                    let currentStorageO = JSON.parse(currentStorage)
                    currentStorageO[columnIdx] = column.visible()
                    localStorage.setItem(`tickware_export_settings_${tableName}`, JSON.stringify(currentStorageO))
                }
            });
        });
        (document.querySelector("#orders_export_from") as HTMLInputElement).addEventListener("change", (e) => {
            this.updateContent("orders")
        });
        (document.querySelector("#orders_export_to") as HTMLInputElement).addEventListener("change", (e) => {
            this.updateContent("orders")
        });
    }

    async updateContent(tableName: string) {
        const from = (document.querySelector(`#${tableName}_export_from`) as HTMLInputElement).value;
        const to = (document.querySelector(`#${tableName}_export_to`) as HTMLInputElement).value;
        const table = this.tables[tableName]
        let currentUrl = table.ajax.url();
        if (currentUrl.indexOf("?") !== -1) {
            currentUrl = currentUrl.split("?")[0]
        }
        const newUrl = `${currentUrl}?from=${btoa(from)}&to=${btoa(to)}`
        this.elements[tableName].closest(".card").find(".loading-body").removeClass("d-none")
        this.elements[tableName].closest(".card").find(".card-datatable").addClass("d-none")
        await table.ajax.url(newUrl).load(() => {
            this.elements[tableName].closest(".card").find(".loading-body").addClass("d-none")
            this.elements[tableName].closest(".card").find(".card-datatable").removeClass("d-none")
        })
    }
}